<style scoped lang="less">
.main-content {
  background: #eff0f7;
}
.table-box::-webkit-scrollbar {
  width: 12px;
}
.table-box::-webkit-scrollbar-track {
  border-radius: 10px;
}
.table-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.table-box::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.table-box {
  flex: 1;
  overflow: auto;

  margin-top: 10px;
  margin-left: 10px;
  border-top-left-radius: 8px;
  /deep/.el-card__body {
    padding: 0;
    display: flex;
    justify-content: space-around;
  }

  .report-table {
    border-left: none;
    border-right: none;
  }
}
.table-left {
  width: 50%;
  min-height: calc(100vh - 120px);

  .table-left-content {
    margin-top: 10px;
  }

  .table-left-border {
    border-top: 1px solid #fff;
    border-right: 1px solid black;
    // border-bottom: 1px solid black;
  }
}
.table-right {
  width: calc(50% - 15px);
  margin-left: 5px;

  .table-right-content {
    margin-top: 10px;
  }

  .table-right-border {
    border-top: 1px solid #fff;
    border-left: 1px solid black;
    // border-bottom: 1px solid black;

    .table-content {
      overflow: hidden;

      .content-title {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        padding: 15px;
        margin-left: 5px;
        border-bottom: 1px solid rgb(223, 220, 220);
        div {
          border-left: 4px solid rgb(1, 72, 226);
          padding-left: 5px;
        }
      }

      .table-content-right {
        margin: 10px 0;
        height: 300px;
      }
    }
  }
}
.nurse-info {
  font-size: 16px;

  .info-content {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;

    .sign-img {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .receive-time::before {
        content: '(';
        margin: 0 5px;
      }
      .receive-time::after {
        content: ')';
        margin-left: 5px;
      }
    }
    img {
      width: 100px;
      height: 50px;
    }
  }
}
.table-title {
  text-align: center;
}
</style>

<template>
  <div class="main-content">
    <div class="crt-content">
      <template v-if="isReadonlyStatus === 'bqhs'">
        <el-button
          v-if="this.leftDataList.length !== 0"
          type="success"
          class="common-button commonBtn"
          icon="el-icon-check"
          v-debounce="saveFormBQHS"
          :disabled="
            bqhsData?.writerSignStatus === 3 ||
            bqhsData?.writerSignStatus === 4 ||
            bqhsPromptText === '已签名' ||
            bqhsPromptText === '已发送'
          "
        >
          保存
        </el-button>
        <el-button
          v-if="sshsData?.writerSignStatus === 3"
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit"
          v-debounce="signBQHS"
          :disabled="bqhsData?.writerSignStatus === 2 || bqhsData?.writerSignStatus === 3"
        >
          签收
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit"
          v-debounce="openDialogBQHS"
          :disabled="
            bqhsData?.writerSignStatus === 2 ||
            bqhsData?.writerSignStatus === 3 ||
            bqhsData?.writerSignStatus === 4
          "
        >
          签名
        </el-button>

        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit-outline"
          v-debounce="openSignDialogBQHS"
          :disabled="bqhsData?.writerSignStatus === 3 || bqhsData?.writerSignStatus === 4"
        >
          提交
        </el-button>
      </template>
      <template v-if="isReadonlyStatus === 'sshs'">
        <el-button
          v-if="this.rightDataList.length !== 0"
          type="success"
          class="common-button commonBtn"
          icon="el-icon-check"
          v-debounce="saveFormSSHS"
          :disabled="
            sshsData?.writerSignStatus === 3 ||
            sshsData?.writerSignStatus === 4 ||
            sshsPromptText === '已签名' ||
            sshsPromptText === '已发送'
          "
        >
          保存
        </el-button>
        <el-button
          v-if="bqhsData?.writerSignStatus === 3"
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit"
          v-debounce="openDialogSSHS"
          :disabled="sshsData?.writerSignStatus === 2 || sshsData?.writerSignStatus === 3"
        >
          签收
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit"
          v-debounce="signSSHS"
          :disabled="
            sshsData?.writerSignStatus === 2 ||
            sshsData?.writerSignStatus === 3 ||
            sshsData?.writerSignStatus === 4
          "
        >
          签名
        </el-button>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-edit-outline"
          v-debounce="openSignDialogSSHS"
          :disabled="sshsData?.writerSignStatus === 3 || sshsData?.writerSignStatus === 4"
        >
          提交
        </el-button>
      </template>
      <div>
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-upload"
          v-debounce="uploadPDF"
          :loading="loading"
        >
          导出PDF
        </el-button>
      </div>
    </div>
    <el-card class="table-box">
      <div class="table-left">
        <div class="table-left-border">
          <h2 class="table-title">
            病区护士
            <span>→</span>
            手术护士
          </h2>
          <template v-if="leftDataList.length > 0">
            <div class="nurse-info">
              <div class="info-content">
                <div class="sign-img">
                  <span>病区护士签名：</span>
                  <div>
                    <span>{{ bqhsData?.writerName }}</span>
                    <span class="receive-time">{{ bqhsData?.writeTime }}</span>
                  </div>
                </div>
                <div class="sign-img">
                  <span>手术护士签名：</span>
                  <span>{{ bqhsData?.receiverName }}</span>
                  <span class="receive-time">{{ bqhsData?.receivePicTime }}</span>
                </div>
              </div>
            </div>
            <div class="table-left-content">
              <div class="assessment-basic-information-body">
                <div v-if="leftDataList.length >= 2">
                  <el-tabs type="card" v-model="leftActiveName" @tab-click="handleLeftTabsClick">
                    <el-tab-pane
                      v-for="(item, index) in leftDataList"
                      :key="item.id"
                      :label="item.title"
                      :name="String(index)"
                      style="height: 100%; overflow: hidden"
                    >
                      <electronic-table
                        :ref="'leftTab' + index"
                        :data="leftDataList[Number(index)]?.data"
                        @save="saveLeftEFormInfo"
                        :isReadonly="
                          isReadonlyStatus === 'sshs' ||
                          bqhsPromptText === '已签名' ||
                          bqhsPromptText === '已发送' ||
                          bqhsPromptText === '已结束' ||
                          !isReadonlyStatus
                        "
                      />
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <div v-if="leftDataList.length < 2">
                  <electronic-table
                    ref="LeftElectronicTable"
                    :data="leftDataList[0]?.data"
                    @save="leftDialogSave"
                    :isReadonly="
                      isReadonlyStatus === 'sshs' ||
                      bqhsPromptText === '已签名' ||
                      bqhsPromptText === '已发送' ||
                      bqhsPromptText === '已结束' ||
                      !isReadonlyStatus
                    "
                  />
                </div>
              </div>
            </div>
          </template>
          <div v-else>
            <el-empty description="暂无相关信息"></el-empty>
          </div>
        </div>
      </div>
      <div class="table-right">
        <div class="table-right-border">
          <h2 class="table-title">
            手术护士
            <span>→</span>
            病区护士
          </h2>
          <template v-if="rightDataList.length > 0">
            <div class="nurse-info">
              <div class="info-content">
                <div class="sign-img">
                  <span>手术护士签名：</span>
                  <div>
                    <span>{{ sshsData?.writerName }}</span>
                    <span class="receive-time">{{ sshsData?.writeTime }}</span>
                  </div>
                </div>
                <div class="sign-img">
                  <span>病区护士签名：</span>
                  <span>{{ bqhsData?.receiverName }}</span>
                  <span class="receive-time">{{ bqhsData?.receivePicTime }}</span>
                </div>
              </div>
              <div class="table-right-content"></div>
              <div class="assessment-basic-information-body">
                <div v-if="rightDataList.length >= 2">
                  <el-tabs type="card" v-model="rightActiveName" @tab-click="handleRightTabsClick">
                    <el-tab-pane
                      v-for="(item, index) in rightDataList"
                      :key="item.id"
                      :label="item.title"
                      :name="String(index)"
                      style="height: 100%; overflow: hidden"
                    >
                      <electronic-table
                        :ref="'leftTab' + index"
                        :data="rightDataList[Number(index)]?.data"
                        @save="saveRightEFormInfo"
                        :isReadonly="
                          isReadonlyStatus === 'bqhs' ||
                          sshsPromptText === '已签名' ||
                          sshsPromptText === '已发送' ||
                          sshsPromptText === '已结束' ||
                          !isReadonlyStatus
                        "
                      />
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <div v-if="rightDataList.length < 2">
                  <electronic-table
                    ref="rightElectronicTable"
                    :data="rightDataList[0]?.data"
                    @save="rightDialogSave"
                    :isReadonly="
                      isReadonlyStatus === 'bqhs' ||
                      sshsPromptText === '已签名' ||
                      sshsPromptText === '已发送' ||
                      sshsPromptText === '已结束' ||
                      !isReadonlyStatus
                    "
                  />
                </div>
              </div>
            </div>
            <div class="table-content">
              <div class="content-title">护理记录</div>
              <div class="table-content-right">
                <el-table
                  class="report-table"
                  :data="rightTableData"
                  :header-cell-style="{ padding: 0 }"
                  style="width: 100%"
                  border
                  height="100%"
                >
                  <el-table-column
                    header-align="center"
                    label="时间"
                    min-width="160"
                    fixed="left"
                    align="center"
                    prop="recordDate"
                  ></el-table-column>
                  <el-table-column
                    header-align="center"
                    label="心率 次/分"
                    width="90"
                    align="center"
                    prop="hr"
                  ></el-table-column>
                  <el-table-column
                    header-align="center"
                    label="血压 mmHg"
                    width="90"
                    align="center"
                    prop="bp"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.row.bpSys && scope.row.bpDia">
                        {{ scope.row.bpSys }}/{{ scope.row.bpDia }}
                      </div>
                      <div v-else>{{ scope.row.bp }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    header-align="center"
                    label="呼吸 次/分"
                    width="90"
                    align="center"
                    prop="rr"
                  ></el-table-column>
                  <el-table-column
                    header-align="center"
                    label="SPO2 %"
                    align="center"
                    prop="spo2"
                    width="90"
                  ></el-table-column>
                  <el-table-column
                    label="病情变化及特殊处理"
                    align="center"
                    width="150"
                    show-overflow-tooltip
                    prop="niOperateTitle"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </template>
          <div v-else>
            <el-empty description="暂无相关信息"></el-empty>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 签名弹窗 -->
    <el-dialog
      :title="nurseTypeName + '签名'"
      :visible.sync="signed_Visible"
      width="45%"
      :modal="false"
      top="20vh"
    >
      <div>
        <h4>请在下方签名</h4>
        <el-divider style="background: #cccc99"></el-divider>
        <div style="width: 300px">
          <el-input v-model="signPwd" placeholder="请输入签名密码"></el-input>
        </div>

        <!-- <vue-esign
          ref="esign"
          :width="800"
          :height="300"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
        -->
        <el-divider style="background: #cccc99"></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="signing" type="success" size="mini">确认</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ElectronicTable from './components/ElectronicTable.vue'
import { PDF_HOST } from '../../../utils/globalVariable'

export default {
  name: 'NurseIntervenes',
  components: {
    ElectronicTable
  },
  data() {
    return {
      bqhsPromptText: null,
      sshsPromptText: null,
      loading: false,
      // json数据
      dataList: [],
      // 表名
      tableTitle: null,
      signPwd: '',
      leftActiveName: '0',
      rightActiveName: '0',
      leftDataList: [],
      rightDataList: [],
      leftTableList: [],
      rightTableList: [],
      leftOpsTage: 'ZYHSJJD', // 病区护士到部门护士
      rightOpsTage: 'SSHSJJD', // 部门护士到病区护士
      rightTableData: [],
      // 右边特殊护理时展示
      nurseTypeName: '',
      // 签名框
      signed_Visible: false,
      //报告签名参数
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '#ffffff',
      resultImg: '',
      isCrop: false,
      // 保存只读状态
      isReadonlyStatus: false,
      // 病区护士信息
      bqhsData: null,
      // 手术护士信息
      sshsData: null,
      sheetType: '',
      dialogStatus: false,
      menuListData: null
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    currentPatient: {
      handler(val) {
        if (val) {
          this.getMenuIndex()
          // 查询交接单状态
          this.getTableStatus()
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {
    this.getLoginPerson()
  },
  methods: {
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.initFetchData()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.$route.query.operationId}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.initFetchData()
          }
        })
      }
    },

    // 查询当前登录人的角色
    getLoginPerson() {
      this.$api.get('/v1/webconsole/user/roles').then((res) => {
        if (res.data && res.data.data) {
          let count = 0
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i]
            if (element.roleName.includes('住院护士')) {
              count++
              this.isReadonlyStatus = 'bqhs'
            } else if (element.roleName.includes('手术护士')) {
              count++
              this.isReadonlyStatus = 'sshs'
            }
            if (count === 2) {
              this.isReadonlyStatus = 'bqhs'
            } else if (count === 0) {
              this.isReadonlyStatus = false
            }
          }
        }
      })
    },
    // 查询交接单状态
    getTableStatus() {
      if (this.currentPatient) {
        // 病区护士交接单
        this.$api
          .post(
            `/v1/webconsole/nursing/workflow_sheet/list/${this.$route.query.operationId}/ZYHSJJD`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              this.bqhsData = res.data.data
              if (res.data.data.writerSignStatus === 2) {
                this.bqhsPromptText = '已签名'
              } else if (res.data.data.writerSignStatus === 3) {
                this.bqhsPromptText = '已发送'
              } else if (res.data.data.writerSignStatus === 4) {
                this.bqhsPromptText = '已结束'
              }
            }
          })
        // 手术护士交接单
        this.$api
          .post(
            `/v1/webconsole/nursing/workflow_sheet/list/${this.$route.query.operationId}/SSHSJJD`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              this.sshsData = res.data.data
              if (res.data.data.writerSignStatus === 2) {
                this.sshsPromptText = '已签名'
              } else if (res.data.data.writerSignStatus === 3) {
                this.sshsPromptText = '已发送'
              } else if (res.data.data.writerSignStatus === 4) {
                this.sshsPromptText = '已结束'
              }
            }
          })
      }
    },
    // 初始化请求数据
    initFetchData() {
      if (this.currentPatient) {
        // 左边
        let leftUrl = `/v1/webconsole/eform/pub/aqjc/form/${this.leftOpsTage}`
        this.$api.get(leftUrl).then((res) => {
          if (res.data && res.data.data && res.data.data?.length > 0) {
            this.leftTableList = res.data.data
            this.leftDataList = []
            const promise = this.leftTableList.map(async (item) => {
              let url = `/v1/webconsole/eform/operation/formdata/${this.currentPatient.uuid}/${this.leftOpsTage}`
              let data = {
                formId: item.formId
              }
              const res = await this.$api.get(url, data)
              return res.data.data
            })
            Promise.all(promise)
              .then((result) => {
                result.map((item) => {
                  if (item.length > 0) {
                    this.leftDataList.push({
                      ...item[0],
                      data: []
                    })
                  } else {
                    this.leftDataList.push({
                      data: []
                    })
                  }
                })
              })
              .then(() => {
                this.getTableData('left')
              })
          } else {
            this.$message.warning('暂无相关评估数据！')
          }
        })

        // 右边
        let rightUrl = `/v1/webconsole/eform/pub/aqjc/form/${this.rightOpsTage}`
        this.$api.get(rightUrl).then((res) => {
          if (res.data && res.data.data && res.data.data?.length > 0) {
            this.rightTableList = res.data.data
            this.rightDataList = []
            const promise = this.rightTableList.map(async (item) => {
              let url = `/v1/webconsole/eform/operation/formdata/${this.currentPatient.uuid}/${this.rightOpsTage}`
              let data = {
                formId: item.formId
              }
              const res = await this.$api.get(url, data)
              return res.data.data
            })
            Promise.all(promise)
              .then((result) => {
                result.map((item) => {
                  if (item.length > 0) {
                    this.rightDataList.push({
                      ...item[0],
                      data: []
                    })
                  } else {
                    this.rightDataList.push({
                      data: []
                    })
                  }
                })
              })
              .then(() => {
                this.getTableData('right')
              })
          } else {
            this.$message.warning('暂无相关评估数据！')
          }
        })

        // let url = `/v1/webconsole/niecg/ecg/${this.$route.query.operationId}`
        // this.$api.get(url).then((res) => {
        //   if (res.data && res.data.data) {
        //     const data = res.data.data.filter((item) => {
        //       if (!item.niOperateId && !item.niOperateTitle) {
        //         return false
        //       }
        //       return true
        //     })
        //     this.rightTableData = data
        //   }
        // })
      }
    },
    getTableData(type) {
      if (type === 'left') {
        for (let i = 0; i < this.leftDataList.length; i++) {
          const element = this.leftDataList[i]
          if (element.id) {
            let url = `/v1/webconsole/eform/elements/${element.formId}/${element.id}`
            this.$api.get(url).then((res) => {
              if (res.data && res.data.data) {
                this.$set(element, 'data', res.data.data)
                this.$set(element, 'title', this.leftTableList[i].formName)
              }
            })
          } else {
            let url = '/v1/webconsole/eform/newform'
            let data = {
              formId: this.leftTableList[i].formId
            }
            this.$api.get(url, data).then((res) => {
              if (res.data && res.data.data) {
                this.$set(element, 'data', res.data.data)
                this.$set(element, 'title', this.leftTableList[i].formName)
                this.$set(element, 'formId', this.leftTableList[i].formId)
              }
            })
          }
        }
      } else if (type === 'right') {
        for (let i = 0; i < this.rightDataList.length; i++) {
          const element = this.rightDataList[i]
          if (element.id) {
            let url = `/v1/webconsole/eform/elements/${element.formId}/${element.id}`
            this.$api.get(url).then((res) => {
              if (res.data && res.data.data) {
                this.$set(element, 'data', res.data.data)
                this.$set(element, 'title', this.rightTableList[i].formName)
              }
            })
          } else {
            let url = '/v1/webconsole/eform/newform'
            let data = {
              formId: this.rightTableList[i].formId
            }
            this.$api.get(url, data).then((res) => {
              if (res.data && res.data.data) {
                this.$set(element, 'data', res.data.data)
                this.$set(element, 'title', this.rightTableList[i].formName)
                this.$set(element, 'formId', this.rightTableList[i].formId)
              }
            })
          }
        }
      }
    },

    // 病区护士保存
    saveFormBQHS() {
      this.saveLeftForm()
    },
    // 手术护士保存
    saveFormSSHS() {
      this.saveRightForm()
    },
    // 病区护士签名
    openDialogBQHS() {
      this.nurseTypeName = '病区护士'
      this.sheetType = 'ZYHSJJD'
      this.signed_Visible = !this.signed_Visible
    },
    // 病区护士签收
    signBQHS() {
      this.nurseTypeName = '病区护士'
      this.sheetType = 'SSHSJJD'
      this.signed_Visible = !this.signed_Visible
    },
    // 手术护士签收
    openDialogSSHS() {
      this.nurseTypeName = '手术护士'
      this.sheetType = 'ZYHSJJD'
      this.signed_Visible = !this.signed_Visible
    },
    // 手术护士签名
    signSSHS() {
      this.nurseTypeName = '手术护士'
      this.sheetType = 'SSHSJJD'
      this.signed_Visible = !this.signed_Visible
    },
    // 病区护士提交
    openSignDialogBQHS() {
      this.sheetType = 'ZYHSJJD'

      this.$api
        .get(
          `/v1/webconsole/nursing/workflow_sheet/send/${this.$route.query.operationId}/${this.sheetType}`
        )
        .then((res) => {
          console.log(res)
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
          }
          this.$nextTick(() => {
            this.getTableStatus()
          })
        })
        .catch(() => {})
    },
    // 手术护士提交
    openSignDialogSSHS() {
      this.nurseTypeName = '手术护士'
      this.sheetType = 'SSHSJJD'

      this.$api
        .get(
          `/v1/webconsole/nursing/workflow_sheet/send/${this.$route.query.operationId}/${this.sheetType}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
          }
          this.$nextTick(() => {
            this.getTableStatus()
          })
        })
        .catch(() => {})
    },
    // 保存左边数据
    saveLeftForm() {
      this.nurseTypeName = '病区护士'
      this.sheetType = 'ZYHSJJD'
      if (this.leftDataList.length > 1) {
        eval('this.$refs.leftTab' + this.leftActiveName)[0].Save()
      } else {
        this.$refs.LeftElectronicTable.Save()
      }
    },
    // 保存左边数据
    leftDialogSave(val) {
      this.saveFormData(val)
    },
    handleLeftTabsClick() {},
    saveLeftEFormInfo(dataList) {
      dataList.items.forEach((item) => {
        item.elementId = item.id
      })
      let url =
        '/v1/webconsole/nursing/workflow_sheet/save' +
        `/${this.$route.query.operationId}` +
        `/${this.sheetType}`
      let data = {
        formId: '',
        id: '',
        ...dataList
      }

      if (this.leftDataList[Number(this.leftActiveName)]?.id) {
        data.formId = this.leftDataList[Number(this.leftActiveName)].formId
        data.id = this.leftDataList[Number(this.leftActiveName)].id
      } else {
        data.formId = this.leftDataList[Number(this.leftActiveName)].formId
      }

      this.$api.post(url, data).then(
        (res) => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
            this.initFetchData()
            this.$nextTick(() => {
              this.getTableStatus()
            })
          }
        },
        () => {
          this.loading = false
        }
      )
    },
    // 保存右边数据
    saveRightForm() {
      this.nurseTypeName = '手术护士'
      this.sheetType = 'SSHSJJD'
      if (this.rightDataList.length > 1) {
        eval('this.$refs.rightTab' + this.rightActiveName)[0].Save()
      } else {
        this.$refs.rightElectronicTable.Save()
      }
    },
    // 保存右边数据
    rightDialogSave(val) {
      this.saveFormData(val)
    },
    handleRightTabsClick() {},
    saveRightEFormInfo(dataList) {
      dataList.items.forEach((item) => {
        item.elementId = item.id
      })
      let url =
        '/v1/webconsole/nursing/workflow_sheet/save' +
        `/${this.$route.query.operationId}` +
        `/${this.sheetType}`
      let data = {
        formId: '',
        id: '',
        ...dataList
      }

      if (this.rightDataList[Number(this.leftActiveName)]?.id) {
        data.formId = this.rightDataList[Number(this.leftActiveName)].formId
        data.id = this.rightDataList[Number(this.leftActiveName)].id
      } else {
        data.formId = this.rightDataList[Number(this.leftActiveName)].formId
      }

      this.$api.post(url, data).then(
        (res) => {
          this.$message.success(res.data.msg)
          this.initFetchData()
          this.$nextTick(() => {
            this.getTableStatus()
          })
        },
        () => {
          this.loading = false
        }
      )
    },
    saveFormData(val) {
      if (this.isReadonlyStatus === 'sshs') {
        this.saveRightEFormInfo(val)
      } else if (this.isReadonlyStatus === 'bqhs') {
        this.saveLeftEFormInfo(val)
      }
    },

    // 签名
    signing() {
      const params = new FormData()
      params.append('pwd', this.signPwd)
      let url
      if (this.isReadonlyStatus === 'sshs') {
        if (this.sheetType === 'ZYHSJJD') {
          url =
            '/v1/webconsole/nursing/workflow_sheet/receiver/sign' +
            `/${this.$route.query.operationId}` +
            `/${this.sheetType}`
        } else {
          url =
            '/v1/webconsole/nursing/workflow_sheet/sign' +
            `/${this.$route.query.operationId}` +
            `/${this.sheetType}`
        }
      } else if (this.isReadonlyStatus === 'bqhs') {
        if (this.sheetType === 'ZYHSJJD') {
          url =
            '/v1/webconsole/nursing/workflow_sheet/sign' +
            `/${this.$route.query.operationId}` +
            `/${this.sheetType}`
        } else {
          url =
            '/v1/webconsole/nursing/workflow_sheet/receiver/sign' +
            `/${this.$route.query.operationId}` +
            `/${this.sheetType}`
        }
      }
      this.$api.post(url, params).then(() => {
        this.$message.success('签名完成')
        this.signPwd = ''
        this.signed_Visible = !this.signed_Visible
        this.getTableStatus()
        this.initFetchData()
      })
    },

    // 导出pdf
    uploadPDF() {
      this.loading = true
      this.$api
        .get(`/v1/webconsole/nursing/workflow_sheet/pdf/${this.currentPatient.uuid}`)
        .then((res) => {
          if (res.data.data) {
            this.loading = false
            var s = encodeURI(res.data.data)
            if (val.pdfFileName.includes('http')) {
              window.open(s, '_blank')
            } else {
              window.open(PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`, '_blank')
            }
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
