<style lang="less">
.error-message {
  color: red;
  white-space: nowrap;
}
.is-invalid .el-input__inner {
  border-color: red;
}
.component {
  padding-left: 10px;
  padding-bottom: 20px;
  .content-title {
    width: 99%;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid rgb(223, 220, 220);
    div {
      border-left: 4px solid rgb(1, 72, 226);
      padding-left: 5px;
    }
  }

  .deep {
    width: 100%;
  }
  .content-basic {
    width: 99%;
    min-height: 30px;
    padding: 10px 0;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .basic-div {
    min-width: 30%;
    min-height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    font-size: 16px;
  }
  /deep/.el-radio__input {
    display: none !important;
  }
  .select-title {
    min-width: 150px;
  }
  .trendsTable {
    width: 100%;
    div {
      margin: 0;
    }
    .trendsTableHeader {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;
    }
    .trendsTableContent {
      .table-thead-fix {
        position: sticky;
        top: 0;
        z-index: 99;
      }
      .table-cell-fix-left {
        position: sticky;
        left: 0;
      }
      .table-cell-fix-right {
        position: sticky;
        right: 0;
        border-left: 1px solid #f3f3f3;
      }
      .table {
        max-height: 320px;
        overflow: auto;
        background-color: #fff;
        .thead {
          width: 100%;
          display: flex;
          align-items: center;
          .cell {
            flex: 0 0 200px;
            padding: 6px 0;
            font-size: 14px;
            color: #000;
            font-weight: bold;
            text-align: center;
            border: 1px solid #dddddd;
            border-right: none;
            background-color: #f3f3f3;
            &:nth-last-child(1) {
              flex: 0 0 120px;
              border-right: 1px solid #dddddd;
              border-top-right-radius: 5px;
            }
            &:nth-child(1) {
              border-top-left-radius: 5px;
            }
          }
        }

        .tbody {
          .row {
            display: flex;
            align-items: center;
            .cell {
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 32px;
              flex: 0 0 200px;
              font-size: 14px;
              border: 1px solid #dddddd;
              border-top: none;
              border-right: none;
              background-color: #fff;
              overflow: hidden;
              span {
                width: 180px;
                overflow: hidden;
              }
              .dataTableDate {
                width: 160px;
              }
              .content {
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                .is-bordered {
                  margin: 0;
                }
              }
              .el-button {
                padding: 5px;
              }
              &:nth-last-child(1) {
                flex: 0 0 120px;
                border-right: 1px solid #dddddd;
                border-bottom-right-radius: 5px;
              }
              &:nth-child(1) {
                border-bottom-left-radius: 5px;
              }
            }
          }
        }
      }
      .table::-webkit-scrollbar {
        height: 12px;
        width: 10px;
      }
      .table::-webkit-scrollbar-track {
        border-radius: 5px;
      }
      .table::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.1);
      }
      .table::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      .table::-webkit-scrollbar-thumb:window-inactive {
        background: #ccc;
      }
    }
  }
  /deep/.radio {
    word-break: normal;
    .errorInput {
      display: flex;
      align-items: center;
    }
    .el-radio__input {
      display: none !important;
    }
    .el-checkbox-group,
    .el-radio-group {
      margin: 0;
      .is-bordered {
        margin: 0 0.9375rem 0.3125rem 0;
      }
    }
    .el-input__inner {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    .is-bordered {
      padding: 12px 20px 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 30px;
      line-height: 5px;
    }
    // 日期选择框
    .el-date-editor--date {
      .el-input__inner {
        padding: 0 30px;
      }
    }
    .el-checkbox {
      padding-top: 5px;
    }
    // 只读
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
      border-color: #53565c;
    }
    .el-input.is-disabled .el-input__inner {
      background-color: #f5f7fa;
      border-color: #e4e7ed;
      color: #000;
      cursor: not-allowed;
    }
    .el-input .el-input__inner {
      color: #000;
    }
    .el-textarea .el-textarea__inner {
      color: #000;
    }
    .is-disabled .el-textarea__inner::placeholder {
      color: #000;
    }
    /* 谷歌 */
    .is-disabled .el-textarea__inner::-webkit-input-placeholder {
      color: #000;
    }
    /* 火狐 */
    .is-disabled .el-textarea__inner:-moz-placeholder {
      color: #000;
    }
    /*ie*/
    .is-disabled .el-textarea__inner:-ms-input-placeholder {
      color: #000;
    }

    .is-disabled .el-input__inner::placeholder {
      color: #000;
    }
    /* 谷歌 */
    .is-disabled .el-input__inner::-webkit-input-placeholder {
      color: #000;
    }
    /* 火狐 */
    .is-disabled .el-input__inner:-moz-placeholder {
      color: #000;
    }
    /*ie*/
    .is-disabled .el-input__inner:-ms-input-placeholder {
      color: #000;
    }
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      color: #53565c;
    }
    .el-radio.is-bordered.is-disabled.is-checked {
      border: 2px solid #53565c;
      .el-radio__label {
        color: #53565c;
      }
    }
    .el-radio__input.is-disabled + span.el-radio__label {
      color: #53565c;
    }
    .el-textarea.is-disabled {
      .el-textarea__inner {
        color: #000;
      }
    }
  }
  .div {
    width: 100%;
  }
  /deep/.el-checkbox-group,
  .el-radio-group {
    margin: 0;
  }
  .radio-textarea {
    width: 800px;
  }
  @media screen and (max-width: 1180px) {
    .radio-textarea {
      width: 400px;
    }
  }
  div {
    margin-right: 10px;
  }
  /deep/.el-input__inner {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 30px;
    line-height: 30px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  /deep/.is-bordered {
    padding: 12px 20px 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
    height: 30px;
    line-height: 5px;
  }
}

.input_has_unit {
  margin-left: 0px !important;
}
</style>

<template>
  <div class="component">
    <div class="list" v-for="(item, index) in tabData" :key="index">
      <div v-if="item.type === 'bannerTitle'" class="content-title">
        <div>
          {{ item.text }}
        </div>
      </div>
      <div class="content-basic" v-else-if="item.type === 'text'">
        {{ item.text }}
      </div>
      <div class="deep" v-else-if="item.type === 'newLine'"></div>
      <template v-else-if="item.type !== 'pageTitle'">
        <template v-if="item.isShow">
          <template v-if="item.isShow === item.parentValue">
            <div :class="item.type === 'div' ? 'basic-child basic-div' : 'basic-div'">
              <div v-if="item.treeLevel" :style="{ 'margin-left': item.treeLevel + 'rem' }"></div>
              <div v-if="item?.preText?.trim()">
                {{ item?.preText }}
              </div>
              <div
                class="select-title"
                v-if="item.tips?.trim() || item.text?.trim() || item.title?.trim()"
              >
                <el-popover
                  v-if="item.tips"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.tips"
                >
                  <div slot="reference">
                    <i class="el-icon-question" style="cursor: pointer"></i>
                    {{ item.text || item.title }}
                  </div>
                </el-popover>
                <span v-else>
                  {{ item.text || item.title }} {{ item.title && !item.text ? ':' : '' }}
                </span>
              </div>
              <template>
                <div class="radio" v-if="item.type === 'radio'">
                  <el-radio-group v-model="item.value">
                    <template v-if="item.items">
                      <el-radio
                        v-for="ele in item.items"
                        :key="ele.id"
                        :label="ele.title"
                        @click.native.prevent="selectRadio(item, ele)"
                        border
                        :disabled="isReadonly"
                      >
                        {{ ele.title }}
                      </el-radio>
                    </template>
                  </el-radio-group>
                </div>
                <div class="radio" v-if="item.type === 'checkbox'">
                  <el-checkbox-group v-model="item.value">
                    <template v-if="item.items">
                      <el-checkbox
                        v-for="ele in item.items"
                        :key="ele.id"
                        :label="ele.title"
                        :disabled="isReadonly"
                      >
                        {{ ele.title }}
                      </el-checkbox>
                    </template>
                  </el-checkbox-group>
                </div>
                <div class="radio" v-if="item.type === 'input'">
                  <template v-if="item.dataType === 'number' || item.dataType === 'int'">
                    <template v-if="item.title.includes('次步行') || item.title.includes('次握力')">
                      <el-input
                        placeholder="请输入"
                        type="number"
                        v-model.trim="item.value"
                        @change="getAverage(item)"
                        :min="item.minimum || 0"
                        :max="item.maximum || 99999999999999"
                        size="mini"
                        :disabled="isReadonly"
                      ></el-input>
                    </template>
                    <template v-else>
                      <div class="errorInput">
                        <el-input
                          placeholder="请输入"
                          type="text"
                          v-model.trim="item.value"
                          :class="{ 'is-invalid': item.isInputInvalid }"
                          :disabled="isReadonly"
                          @input="getScore(item)"
                          @keyup.native="handleKeyDown($event)"
                        ></el-input>
                        <span v-if="item.isInputInvalid" class="error-message">
                          {{ item.inputErrorMessage }}
                        </span>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="item.title.includes('次步行') || item.title.includes('次握力')">
                      <el-input
                        placeholder="请输入"
                        type="text"
                        v-model.trim="item.value"
                        @change="getAverage(item)"
                        :disabled="isReadonly"
                      >
                        <template v-if="item.unit" slot="append">
                          {{ item.unit }}
                        </template>
                      </el-input>
                    </template>
                    <template v-else>
                      <el-input
                        placeholder="请输入"
                        type="text"
                        v-model.trim="item.value"
                        :disabled="isReadonly"
                      >
                        <template v-if="item.unit" slot="append">
                          {{ item.unit }}
                        </template>
                      </el-input>
                    </template>
                  </template>
                </div>
                <div class="radio" v-if="item.type === 'text'">
                  <el-tag>{{ item.text }}</el-tag>
                </div>
                <div class="radio radio-textarea" v-if="item.type === 'textarea'">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    maxlength="100"
                    placeholder="请输入"
                    v-model="item.value"
                    :disabled="isReadonly"
                  >
                    <template v-if="item.unit" slot="append">{{ item.unit }}</template>
                  </el-input>
                </div>
                <div class="radio" v-if="item.type === 'select'">
                  <el-select v-model="item.value" placeholder="请选择" :disabled="isReadonly">
                    <el-option
                      v-for="item in item.items"
                      :key="item.id"
                      :label="item.title"
                      :value="item.title"
                    ></el-option>
                  </el-select>
                </div>
                <div class="radio" v-if="item.type === 'dateSelect'">
                  <el-date-picker
                    v-model="item.value"
                    type="date"
                    placeholder="选择日期"
                    clearable
                    align="center"
                    :value-format="item.dateFormat || 'yyyy-MM-dd'"
                    :disabled="isReadonly"
                    :format="item.dateFormat || 'yyyy-MM-dd'"
                  ></el-date-picker>
                </div>
                <div class="radio trendsTable" style="margin: 0" v-if="item.type === 'dataTable'">
                  <div class="trendsTableHeader">
                    <el-button
                      :disabled="isReadonly"
                      style="margin-left: 5px"
                      size="mini"
                      icon="el-icon-plus"
                      @click="addDataTable(item, index)"
                    >
                      <span>添加</span>
                    </el-button>
                  </div>
                  <div class="trendsTableContent">
                    <div class="table">
                      <div class="thead table-thead-fix">
                        <div
                          class="cell"
                          v-for="(ele, index1) in item.efElementExtends"
                          :key="'efElementExtends' + index1"
                        >
                          {{ ele.tableFiled }}
                        </div>
                        <div class="cell table-cell-fix-right">操作</div>
                      </div>
                      <div class="tbody">
                        <div
                          class="row"
                          v-for="(ele2, index2) in item.recordByRowNum"
                          :key="index2"
                        >
                          <div
                            class="cell"
                            v-for="(ele3, index3) in item.recordByRowNum[index2]"
                            :key="index3"
                          >
                            <template
                              v-if="
                                item.efElementExtends[index3].type === 'dateSelect' ||
                                item.efElementExtends[index3].dataType === 'date'
                              "
                            >
                              <template v-if="!isReadonly">
                                <el-date-picker
                                  v-model="ele3.value"
                                  type="date"
                                  placeholder="选择日期"
                                  class="dataTableDate"
                                  clearable
                                  align="center"
                                  :disabled="isReadonly"
                                  value-format="yyyy-MM-dd"
                                  format="yyyy-MM-dd"
                                ></el-date-picker>
                              </template>
                              <template v-else>
                                {{ ele3.value }}
                              </template>
                            </template>
                            <template v-else-if="item.efElementExtends[index3].type === 'radio'">
                              <template v-if="!isReadonly">
                                <el-select
                                  v-model="ele3.value"
                                  placeholder="请选择"
                                  :disabled="isReadonly"
                                >
                                  <template v-if="item.efElementExtends[index3].items">
                                    <el-option
                                      v-for="i in item.efElementExtends[index3].items"
                                      :key="i.id"
                                      :label="i.title"
                                      :value="i.title"
                                    ></el-option>
                                  </template>
                                </el-select>
                              </template>
                              <template v-else>
                                {{ ele3.value }}
                              </template>
                            </template>
                            <template v-else-if="item.efElementExtends[index3].type === 'checkbox'">
                              <template v-if="!isReadonly">
                                <el-select
                                  v-model="ele3.value"
                                  placeholder="请选择"
                                  multiple
                                  collapse-tags
                                  :disabled="isReadonly"
                                >
                                  <template v-if="item.efElementExtends[index3].items">
                                    <el-checkbox-group v-model="ele3.value">
                                      <el-option
                                        v-for="i in item.efElementExtends[index3].items"
                                        :key="i.id"
                                        :label="i.title"
                                        :value="i.title"
                                      >
                                        <el-checkbox
                                          style="pointer-events: none"
                                          :label="i.title"
                                        ></el-checkbox>
                                      </el-option>
                                    </el-checkbox-group>
                                  </template>
                                </el-select>
                              </template>
                              <template v-else>
                                <el-popover
                                  v-if="initTableVal(ele3.value)?.length > 30"
                                  trigger="hover"
                                  placement="bottom"
                                  width="400"
                                >
                                  <span>{{ initTableVal(ele3.value) }}</span>
                                  <div slot="reference" class="name-wrapper">
                                    <span style="cursor: default">
                                      {{ initTableVal(ele3.value) }}
                                    </span>
                                  </div>
                                </el-popover>
                                <span v-else>{{ initTableVal(ele3.value) }}</span>
                              </template>
                            </template>
                            <template
                              v-else-if="
                                item.efElementExtends[index3].dataType === 'textarea' ||
                                item.efElementExtends[index3].type === 'input' ||
                                item.efElementExtends[index3].dataType === 'string'
                              "
                            >
                              <template v-if="!isReadonly">
                                <el-input
                                  placeholder="请输入"
                                  type="text"
                                  v-model.trim="ele3.value"
                                  :disabled="isReadonly"
                                >
                                  <template
                                    v-if="item.efElementExtends[index3]?.unit"
                                    slot="append"
                                  >
                                    {{ item.efElementExtends[index3].unit }}
                                  </template>
                                </el-input>
                              </template>
                              <template v-else>
                                <el-popover
                                  v-if="ele3.value?.length > 30"
                                  trigger="hover"
                                  placement="bottom"
                                  width="400"
                                >
                                  <span>{{ ele3.value }}</span>
                                  <div slot="reference" class="name-wrapper">
                                    <span style="cursor: default">{{ ele3.value }}</span>
                                  </div>
                                </el-popover>
                                <span v-else>{{ ele3.value }}</span>
                              </template>
                            </template>
                            <div v-else>
                              <el-popover
                                v-if="ele3.value?.length > 30"
                                trigger="hover"
                                placement="bottom"
                                width="400"
                              >
                                <span>{{ ele3.value }}</span>
                                <div slot="reference" class="name-wrapper">
                                  <span style="cursor: default">{{ ele3.value }}</span>
                                </div>
                              </el-popover>
                              <span v-else>{{ ele3.value }}</span>
                            </div>
                          </div>
                          <div class="cell table-cell-fix-right">
                            <div class="content">
                              <el-button
                                v-if="!isReadonly"
                                @click="delDataTable(item, index2)"
                                type="danger"
                                circle
                              >
                                <i class="el-icon-close"></i>
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="radio div" v-if="item.type === 'div'">
                  <electronic-table
                    v-if="item.contents"
                    ref="ElectronicTable"
                    :data="item.contents"
                  />
                </div> -->
              </template>
              <div v-if="item?.afterText?.trim()">
                {{ item?.afterText }}
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div :class="item.type === 'div' ? 'basic-child basic-div' : 'basic-div'">
            <div v-if="item.treeLevel" :style="{ 'margin-left': item.treeLevel + 'rem' }"></div>
            <div v-if="item?.preText?.trim()">
              {{ item?.preText }}
            </div>
            <div class="select-title" v-if="item.tips?.trim() || item.text?.trim()">
              <el-popover
                v-if="item.tips"
                placement="top-start"
                width="200"
                trigger="hover"
                :content="item.tips"
              >
                <div slot="reference">
                  <i class="el-icon-question" style="cursor: pointer"></i>
                  {{ item.text }}
                </div>
              </el-popover>
              <span v-else>{{ item.text }}</span>
            </div>
            <template>
              <div class="radio" v-if="item.type === 'radio'">
                <el-radio-group v-model="item.value">
                  <template v-if="item.items">
                    <el-radio
                      v-for="ele in item.items"
                      :key="ele.id"
                      :label="ele.title"
                      @click.native.prevent="selectRadio(item, ele)"
                      border
                      :disabled="isReadonly"
                    >
                      {{ ele.title }}
                    </el-radio>
                  </template>
                </el-radio-group>
              </div>
              <div class="radio" v-if="item.type === 'checkbox'">
                <el-checkbox-group v-model="item.value">
                  <template v-if="item.items">
                    <el-checkbox
                      v-for="ele in item.items"
                      :key="ele.id"
                      :label="ele.title"
                      :disabled="isReadonly"
                    >
                      {{ ele.title }}
                    </el-checkbox>
                  </template>
                </el-checkbox-group>
              </div>
              <div class="radio" v-if="item.type === 'input'">
                <template v-if="item.dataType === 'number' || item.dataType === 'int'">
                  <template
                    v-if="
                      item.title === '身高' ||
                      item.title === '体重' ||
                      item.title === 'BMI' ||
                      item.title === '体表面积'
                    "
                  >
                    <el-input
                      placeholder="请输入"
                      type="number"
                      v-model.trim="item.value"
                      @change="getBMI(item)"
                      :min="item.minimum || 0"
                      :max="item.maximum || 99999999999999"
                      size="mini"
                      :disabled="isReadonly"
                    ></el-input>
                  </template>

                  <template v-else>
                    <div class="errorInput">
                      <el-input
                        placeholder="请输入"
                        type="text"
                        v-model.trim="item.value"
                        :class="{ 'is-invalid': item.isInputInvalid }"
                        :disabled="isReadonly"
                        @input="getScore(item)"
                        @keyup.native="handleKeyDown($event)"
                      ></el-input>
                      <span v-if="item.isInputInvalid" class="error-message">
                        {{ item.inputErrorMessage }}
                      </span>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template
                    v-if="
                      item.title === '身高' ||
                      item.title === '体重' ||
                      item.title === 'BMI' ||
                      item.title === '体表面积'
                    "
                  >
                    <el-input
                      placeholder="请输入"
                      type="text"
                      v-model.trim="item.value"
                      @change="getBMI(item)"
                      :disabled="isReadonly"
                    >
                      <template v-if="item.unit" slot="append">
                        {{ item.unit }}
                      </template>
                    </el-input>
                  </template>
                  <template v-else>
                    <el-input
                      placeholder="请输入"
                      type="text"
                      v-model.trim="item.value"
                      :disabled="isReadonly"
                    >
                      <template v-if="item.unit" slot="append">
                        {{ item.unit }}
                      </template>
                    </el-input>
                  </template>
                </template>
              </div>
              <div class="radio" v-if="item.type === 'text'">
                <el-tag>{{ item.text }}</el-tag>
              </div>
              <div class="radio radio-textarea" v-if="item.type === 'textarea'">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  maxlength="100"
                  placeholder="请输入"
                  v-model="item.value"
                  :disabled="isReadonly"
                >
                  <template v-if="item.unit" slot="append">{{ item.unit }}</template>
                </el-input>
              </div>
              <div class="radio" v-if="item.type === 'select'">
                <el-select v-model="item.value" placeholder="请选择" :disabled="isReadonly">
                  <el-option
                    v-for="item in item.items"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </div>
              <div class="radio" v-if="item.type === 'dateSelect'">
                <el-date-picker
                  v-model="item.value"
                  type="date"
                  placeholder="选择日期"
                  clearable
                  align="center"
                  :value-format="item.dateFormat || 'yyyy-MM-dd'"
                  :disabled="isReadonly"
                  :format="item.dateFormat || 'yyyy-MM-dd'"
                ></el-date-picker>
              </div>
              <div class="radio trendsTable" style="margin: 0" v-if="item.type === 'dataTable'">
                <div class="trendsTableHeader">
                  <span>{{ item.title }}:</span>
                  <el-button
                    :disabled="isReadonly"
                    style="margin-left: 5px"
                    size="mini"
                    icon="el-icon-plus"
                    @click="addDataTable(item, index)"
                  >
                    <span>添加</span>
                  </el-button>
                </div>
                <div class="trendsTableContent">
                  <div class="table">
                    <div class="thead table-thead-fix">
                      <div
                        class="cell"
                        v-for="(ele, index1) in item.efElementExtends"
                        :key="'efElementExtends' + index1"
                      >
                        {{ ele.tableFiled }}
                      </div>
                      <div class="cell table-cell-fix-right">操作</div>
                    </div>
                    <div class="tbody">
                      <div class="row" v-for="(ele2, index2) in item.recordByRowNum" :key="index2">
                        <div
                          class="cell"
                          v-for="(ele3, index3) in item.recordByRowNum[index2]"
                          :key="index3"
                        >
                          <template
                            v-if="
                              item.efElementExtends[index3].type === 'dateSelect' ||
                              item.efElementExtends[index3].dataType === 'date'
                            "
                          >
                            <template v-if="!isReadonly">
                              <el-date-picker
                                v-model="ele3.value"
                                type="date"
                                placeholder="选择日期"
                                class="dataTableDate"
                                clearable
                                align="center"
                                :disabled="isReadonly"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                              ></el-date-picker>
                            </template>
                            <template v-else>
                              {{ ele3.value }}
                            </template>
                          </template>
                          <template v-else-if="item.efElementExtends[index3].type === 'radio'">
                            <template v-if="!isReadonly">
                              <el-select
                                v-model="ele3.value"
                                placeholder="请选择"
                                :disabled="isReadonly"
                              >
                                <template v-if="item.efElementExtends[index3].items">
                                  <el-option
                                    v-for="i in item.efElementExtends[index3].items"
                                    :key="i.id"
                                    :label="i.title"
                                    :value="i.title"
                                  ></el-option>
                                </template>
                              </el-select>
                            </template>
                            <template v-else>
                              {{ ele3.value }}
                            </template>
                          </template>
                          <template v-else-if="item.efElementExtends[index3].type === 'checkbox'">
                            <template v-if="!isReadonly">
                              <el-select
                                v-model="ele3.value"
                                placeholder="请选择"
                                multiple
                                collapse-tags
                                :disabled="isReadonly"
                              >
                                <template v-if="item.efElementExtends[index3].items">
                                  <el-checkbox-group v-model="ele3.value">
                                    <el-option
                                      v-for="i in item.efElementExtends[index3].items"
                                      :key="i.id"
                                      :label="i.title"
                                      :value="i.title"
                                    >
                                      <el-checkbox
                                        style="pointer-events: none"
                                        :label="i.title"
                                      ></el-checkbox>
                                    </el-option>
                                  </el-checkbox-group>
                                </template>
                              </el-select>
                            </template>
                            <template v-else>
                              <el-popover
                                v-if="initTableVal(ele3.value)?.length > 30"
                                trigger="hover"
                                placement="bottom"
                                width="400"
                              >
                                <span>{{ initTableVal(ele3.value) }}</span>
                                <div slot="reference" class="name-wrapper">
                                  <span style="cursor: default">
                                    {{ initTableVal(ele3.value) }}
                                  </span>
                                </div>
                              </el-popover>
                              <span v-else>{{ initTableVal(ele3.value) }}</span>
                            </template>
                          </template>
                          <template
                            v-else-if="
                              item.efElementExtends[index3].dataType === 'textarea' ||
                              item.efElementExtends[index3].type === 'input' ||
                              item.efElementExtends[index3].dataType === 'string'
                            "
                          >
                            <template v-if="!isReadonly">
                              <el-input
                                placeholder="请输入"
                                type="text"
                                v-model.trim="ele3.value"
                                :disabled="isReadonly"
                              >
                                <template v-if="item.efElementExtends[index3]?.unit" slot="append">
                                  {{ item.efElementExtends[index3].unit }}
                                </template>
                              </el-input>
                            </template>
                            <template v-else>
                              <el-popover
                                v-if="ele3.value?.length > 30"
                                trigger="hover"
                                placement="bottom"
                                width="400"
                              >
                                <span>{{ ele3.value }}</span>
                                <div slot="reference" class="name-wrapper">
                                  <span style="cursor: default">{{ ele3.value }}</span>
                                </div>
                              </el-popover>
                              <span v-else>{{ ele3.value }}</span>
                            </template>
                          </template>
                          <div v-else>
                            <el-popover
                              v-if="ele3.value?.length > 30"
                              trigger="hover"
                              placement="bottom"
                              width="400"
                            >
                              <span>{{ ele3.value }}</span>
                              <div slot="reference" class="name-wrapper">
                                <span style="cursor: default">{{ ele3.value }}</span>
                              </div>
                            </el-popover>
                            <span v-else>{{ ele3.value }}</span>
                          </div>
                        </div>
                        <div class="cell table-cell-fix-right">
                          <div class="content">
                            <el-button
                              v-if="!isReadonly"
                              @click="delDataTable(item, index2)"
                              type="danger"
                              circle
                            >
                              <i class="el-icon-close"></i>
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="radio div" v-if="item.type === 'div'">
                <electronic-table
                  v-if="item.contents"
                  ref="ElectronicTable"
                  :data="item.contents"
                />
              </div> -->
            </template>
            <div v-if="item?.afterText?.trim()">
              {{ item?.afterText }}
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import deepClone from '../../../../plugins/clone'

export default {
  name: 'electronicTable',
  components: {},
  data() {
    return {
      check: true,
      tabData: [],
      childrenId: '',
      childrenVal: '',
      treeId: '',
      treeVal: '',
      treeChildrenVal: '',
      treeLevel: '',
      patientSg: '',
      patientTz: '',
      patientBMI: '',
      KATZId: ''
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          if (val && val.length > 0) {
            this.tabData = deepClone(val)
            this.dealData()
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    getScore(val) {
      if (val.value) {
        if (val.maximum && Number(val.value) > val.maximum) {
          this.$set(val, 'isInputInvalid', true)
          this.$set(val, 'inputErrorMessage', val.title + '数值不能>' + val.maximum)
        } else if (val.minimum && Number(val.value) < val.minimum) {
          this.$set(val, 'isInputInvalid', true)
          this.$set(val, 'inputErrorMessage', val.title + '数值不能<' + val.minimum)
        } else {
          this.$set(val, 'isInputInvalid', false)
          this.$set(val, 'inputErrorMessage', '')
        }
      }
    },
    handleKeyDown(e) {
      var val = e.target.value
      // 限制只能输入一个小数点
      if (val.indexOf('.') != -1) {
        var str = val.substr(val.indexOf('.') + 1)
        if (str.indexOf('.') != -1) {
          val = val.substr(0, val.indexOf('.') + str.indexOf('.') + 1)
        }
      }
      e.target.value = val.replace(/[^0-9.<>]/g, '')
    },
    isEdit() {
      this.childrenId = ''
      this.childrenVal = ''
      this.KATZId = ''
      for (let i = 0; i < this.tabData.length; i++) {
        const ele = this.tabData[i]
        for (let j = 0; j < this.data.length; j++) {
          const element = this.data[j]
          if (ele.id === element.id) {
            ele.value = element.value
          }
        }
      }
      this.dealData()
    },
    Save() {
      this.check = true
      for (let i = 0; i < this.tabData.length; i++) {
        const ele = this.tabData[i]
        for (let j = 0; j < this.data.length; j++) {
          const element = this.data[j]
          if (ele.id === element.id) {
            element.value = ele.value
            if (element.type === 'dataTable') {
              element.extendTable = []
              for (let key in ele.recordByRowNum) {
                let list = []
                for (let k = 0; k < ele.recordByRowNum[key].length; k++) {
                  const e = ele.recordByRowNum[key][k]
                  let value = ''
                  if (typeof e.value === 'object' && !!e.value) {
                    if (e.value.length > 0) {
                      value = JSON.parse(JSON.stringify(e.value)).join(', ')
                    }
                  } else {
                    value = e.value
                  }
                  list.push({ elementId: e.tableId, value: value })
                }
                element.extendTable.push(list)
              }
            }
          }
        }
      }
      this.checkData(this.tabData)
      if (this.check) {
        let arr = JSON.parse(JSON.stringify(this.data))
        let dataList = {
          items: arr,
          examineIds: []
        }
        this.callbackData(dataList)
        this.$emit('save', dataList)
      }
    },
    checkData(data) {
      let isNullTitle = ''
      for (let i = 0; i < data.length; i++) {
        if (this.check) {
          if (data[i]?.controllerName) {
            if (!!data[i].isNull && data[i].isNull === 'false') {
              if (
                !data[i]?.value ||
                data[i]?.value == [] ||
                data[i]?.value == '' ||
                data[i].value == null
              ) {
                if (data[i].isShow !== 'none') {
                  isNullTitle = isNullTitle + (isNullTitle !== '' ? ',' : '') + data[i].title
                }
              }
            }
          }
        }
      }
      if (isNullTitle !== '') {
        this.check = false
        this.$message.warning(isNullTitle + '不能为空！')
        this.$confirm(isNullTitle + '不能为空！是否继续保存', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.check = true
            let dataList = {
              items: arr,
              examineIds: []
            }
            this.callbackData(dataList)
            this.$emit('save', dataList)
          })
          .catch(() => {
            this.check = false
          })
      }
    },
    callbackData(dataList) {
      dataList.items.map((item) => {
        if (item.type === 'checkbox') {
          if (item.value?.length > 0) {
            item.value = item.value?.join(',')
          } else {
            item.value = null
          }
        }
      })
    },
    initTableVal(val) {
      val = val && val.length > 0 ? val.join(',') : ''
      return val
    },
    dealData() {
      this.treeId = ''
      this.treeVal = ''
      this.tabData.map((item) => {
        if (item.type === 'checkbox') {
          if (item.value) {
            if (typeof item.value !== 'object') {
              item.value = item.value?.split(',')
            }
          } else {
            item.value = []
          }
        }
        if (item.type === 'dataTable') {
          let dataTableList = []
          let dataTableId = item.id
          this.tabData.forEach((ele) => {
            if (ele.parentId === dataTableId) {
              dataTableList.push({
                id: ele.id,
                tableFiled: ele.title,
                dataType: ele.dataType,
                type: ele.type,
                unit: ele.unit,
                items: ele.items,
                itemsList: ele.itemsList
              })
            }
          })
          item.efElementExtends = dataTableList
          if (item.extendTable) {
            let newObj = {}
            item.extendTable.forEach((ele, index) => {
              let arr = []
              let count = index + 1
              for (let i = 0; i < item.efElementExtends.length; i++) {
                const element = item.efElementExtends[i]
                if (element.type === 'checkbox') {
                  arr.push({
                    rowNum: count,
                    tableId: element.id,
                    value: []
                  })
                } else {
                  arr.push({
                    rowNum: count,
                    tableId: element.id,
                    value: ''
                  })
                }
              }
              for (let key in ele) {
                for (let j = 0; j < arr.length; j++) {
                  const e = arr[j]
                  if (key === e.tableId) {
                    if (typeof e.value === 'string') {
                      e.value = ele[key]
                    } else {
                      e.value = ele[key]?.split(',') || []
                    }
                  }
                }
              }
              newObj[count] = arr
            })
            this.$set(item, 'recordByRowNum', newObj)
          }
        }
        if (item.value === null && item.defaultValue !== null) {
          item.value = item.defaultValue || null
        }
        if (item.type !== 'newLine') {
          if (item.parentId === '-1') {
            this.childrenId = item.id
            this.childrenVal = item.value
            if (item.title === 'KATZ评分' && item.value === '是') {
              this.KATZId = item.id
            }
          } else {
            if (item.parentId === this.childrenId) {
              if (item.treeLevel !== 0) {
                this.treeId = item.id
                this.treeVal = item.value
                if (this.childrenVal && this.childrenVal === item.parentValue) {
                  item.isShow = item.parentValue
                } else {
                  item.isShow = 'none'
                }
              }
            } else {
              if (item.parentId === this.treeId) {
                if (item.treeLevel > 1) {
                  this.treeLevel = item.treeLevel
                  this.treeChildrenVal = item.value
                  if (this.treeVal && this.treeVal === item.parentValue) {
                    item.isShow = item.parentValue
                  } else {
                    item.isShow = 'none'
                  }
                }
              } else {
                if (item.treeLevel > this.treeLevel) {
                  if (this.treeChildrenVal && this.treeChildrenVal === item.parentValue) {
                    item.isShow = item.parentValue
                  } else {
                    item.isShow = 'none'
                  }
                }
              }
            }
          }
          if (
            (item.type === 'input' && item.dataType === 'number') ||
            (item.dataType === 'int' && item.maximum && item.minimum)
          ) {
            item.isInputInvalid = false
            item.inputErrorMessage = ''
          }
          if (this.tabData[0].title?.includes('指数') || this.tabData[0].title?.includes('评估')) {
            if (item.title === '身高') {
              this.patientSg = item.value
            }
            if (item.title === '体重') {
              this.patientTz = item.value
            }
          }
        }
      })
    },
    getAverage(val) {
      let count = 0
      let num = 0
      for (let i = 0; i < this.tabData.length; i++) {
        const ele = this.tabData[i]
        if (ele.parentId === val.parentId) {
          if (ele.title.includes('次步行') || ele.title.includes('次握力')) {
            if (ele.value) {
              count++
              num = num + Number(ele.value)
            }
          }
          if (ele.title.includes('平均')) {
            ele.value = (num / count).toFixed(2)
          }
        }
      }
    },
    getBMI(val) {
      if (val.title === '身高') {
        if (val.value) {
          this.patientSg = val.value
        } else {
          this.patientSg = ''
        }
      }
      if (val.title === '体重') {
        if (val.value) {
          this.patientTz = val.value
        } else {
          this.patientTz = ''
        }
      }
      if (this.patientSg !== '' && this.patientTz !== '') {
        this.patientBMI =
          (Number(this.patientTz) / Number(this.patientSg) / Number(this.patientSg)) * 10000
        let patientBSA = Math.sqrt((Number(this.patientSg) * Number(this.patientTz)) / 3600)
        for (let i = 0; i < this.tabData.length; i++) {
          const ele = this.tabData[i]
          if (ele.title === 'BMI') {
            ele.value = Math.floor(this.patientBMI * 100) / 100
          }
          if (ele.title === '体表面积') {
            ele.value = Math.floor(patientBSA * 100) / 100
          }
        }
      } else {
        for (let i = 0; i < this.tabData.length; i++) {
          const ele = this.tabData[i]
          if (ele.title === 'BMI' || ele.title === '体表面积') {
            ele.value = ''
          }
        }
      }
    },
    delDataTable(item, index) {
      let newObj = {}
      let count = 1
      for (let key in item.recordByRowNum) {
        if (key !== index) {
          newObj[count] = item.recordByRowNum[key]
          for (let j = 0; j < newObj[count].length; j++) {
            const element = newObj[count][j]
            element.rowNum = count
          }
          count++
        }
      }
      item.recordByRowNum = newObj
    },
    // 添加动态表格
    addDataTable(item) {
      if (item.recordByRowNum && Object.keys(item.recordByRowNum).length > 0) {
        let arr = []
        let newObj = {}
        let count = 1
        for (let key in item.recordByRowNum) {
          newObj[count] = item.recordByRowNum[key]
          count++
          if (count === Object.keys(item.recordByRowNum).length + 1) {
            for (let j = 0; j < item.efElementExtends.length; j++) {
              const ele = item.efElementExtends[j]
              for (let i = 0; i < item.recordByRowNum[key].length; i++) {
                const element = item.recordByRowNum[key][i]
                if (ele.id === element.tableId) {
                  if (ele.dataType === 'checkbox') {
                    arr.push({
                      rowNum: Object.keys(item.recordByRowNum).length + 1,
                      tableId: element.tableId,
                      value: []
                    })
                  } else {
                    arr.push({
                      rowNum: Object.keys(item.recordByRowNum).length + 1,
                      tableId: element.tableId,
                      value: ''
                    })
                  }
                }
              }
            }
            newObj[count] = arr
          }
        }
        this.$set(item, 'recordByRowNum', newObj)
      } else {
        let arr = []
        let newObj = {}
        let count = 1
        for (let i = 0; i < item.efElementExtends.length; i++) {
          const element = item.efElementExtends[i]
          if (element.dataType === 'checkbox') {
            arr.push({
              rowNum: count,
              tableId: element.id,
              value: []
            })
          } else {
            arr.push({
              rowNum: count,
              tableId: element.id,
              value: ''
            })
          }
        }
        newObj[count] = arr
        this.$set(item, 'recordByRowNum', newObj)
      }
    },
    // 单选框取消单选
    selectRadio(item, ele) {
      if (this.isReadonly) return false
      if (item.value === ele.title) {
        item.value = ''
      } else {
        item.value = ele.title
      }
      for (let i = 0; i < this.tabData.length; i++) {
        if (item.id === this.tabData[i].parentId) {
          if (item.value === this.tabData[i].parentValue) {
            this.tabData[i].isShow = this.tabData[i].parentValue
          } else {
            this.tabData[i].isShow = 'none'
            if (this.tabData[i].type === 'checkbox') {
              this.tabData[i].value = []
            } else {
              this.tabData[i].value = null
            }
          }
        }
      }
      if (item.title === 'KATZ评分') {
        let num = 0
        this.KATZId = item.id
        for (let i = 0; i < this.tabData.length; i++) {
          const ele = this.tabData[i]
          if (ele.parentId === item.id) {
            if (ele.value) {
              if (ele.value?.includes('0分')) {
                num
              } else {
                if (ele.value?.includes('1分')) {
                  num++
                }
              }
            }
            if (ele.title.includes('活动评分') || ele.title.includes('总分')) {
              ele.value = num
            }
          }
        }
      }
      if (this.KATZId) {
        if (item.parentId === this.KATZId) {
          let num = 0
          for (let i = 0; i < this.tabData.length; i++) {
            const ele = this.tabData[i]
            if (ele.parentId === this.KATZId) {
              if (typeof ele.value === 'string') {
                if (ele.value?.includes('0分')) {
                  num
                } else {
                  if (ele.value?.includes('1分')) {
                    num++
                  }
                }
              }
              if (ele.title.includes('活动评分') || ele.title.includes('总分')) {
                ele.value = num
              }
            }
          }
        }
      }
    }
  }
}
</script>
